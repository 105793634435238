'use client'

import { useState, Fragment } from 'react'
import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

// Types
import type { HomeProps } from '@/types/components/pages/home'
import type { FooterMenuItem } from '@/types/components/molecules/items/menu'

// Components
const AppbarOrganism = dynamic(() => import('@/components/organisms/appbar'))
const FooterOrganism = dynamic(() => import('@/components/organisms/footer'))
const HomeTemplate = dynamic(() => import('@/components/templates/home'))
const MaskHomeTemplate = dynamic(() => import('@/components/templates/mask/home'))

const HomePage = (props: HomeProps) => {
	// Props
	const { lng } = props

	// Vaiables
	const muiTheme = useTheme()
	const [fontSize, setFontSize] = useState<number>(10)
	const greaterThanMedoium = useMediaQuery(muiTheme.breakpoints.up('md'))

	const menus: FooterMenuItem[] = [
		{ parent_id: null, link_value: '#', title: 'links:contact', group: { title: 'menu' } },
		{ parent_id: null, link_value: '/home', title: 'links:home', group: { title: 'menu' } },
		{ parent_id: null, link_value: '#', title: 'links:about', group: { title: 'menu' } }
	]

	return (
		<Fragment>
			<AppbarOrganism lng={lng} menus={menus} fontSize={fontSize} setFontSize={setFontSize} />

			<Box component="main" width={1}>
				<HomeTemplate lng={lng} fontSize={fontSize} setFontSize={setFontSize} />
				{greaterThanMedoium && <MaskHomeTemplate lng={lng} menus={menus} fontSize={fontSize} setFontSize={setFontSize} />}
			</Box>

			<FooterOrganism lng={lng} fontSize={fontSize} setFontSize={setFontSize} />
		</Fragment>
	)
}

export default HomePage
